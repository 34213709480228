* {
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

@font-face {
  font-family: Petita;
  src: url('fonts/PetitaMedium.ttf');
}
@font-face {
  font-family: Petita;
  src: url('fonts/PetitaBold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: Petita;
  src: url('fonts/PetitaLight.ttf');
  font-weight: lighter;
}
@font-face {
  font-family: HelveticaNeue;
  src: url('fonts/helvetica-neue/HELVETNL.TTF');
}
@font-face {
  font-family: HelveticaNeue;
  src: url('fonts/helvetica-neue/HELVETNB.TTF');
  font-weight: bold;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'HelveticaNeue', 'Petita', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root {
  background-color: #f1eeec;
  max-width: 100vw;
  overflow-x: hidden;
}
